import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Navbar,Collapse,Nav,NavbarBrand,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem, Button} from 'reactstrap';
import Logo from './Logo';
import { ReactComponent as LogoWhite } from '../assets/images/logos/img_logo02.svg';
import globeworldicon from '../assets/images/users/globeworldicon.png';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { loginRequest } from '../B2C';
import '../assets/css/header.css';



const LogoutHeader = () => {
  const { instance} = useMsal();
  const {t} = useTranslation([`page`]);

  const [isOpen, setIsOpen] = React.useState(false);


  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };

  const showMobilemenu = () => {
    document.getElementById('sidebarArea').classList.toggle('showSidebar');
  };

  //const [language, setLanguage] = useState(`JP`);

  const onChangeLangja= () => {
    i18n.changeLanguage('jp')
    //setLanguage(`JP`)
  }

  const onChangeLangen = () => {
    i18n.changeLanguage('en')
    //setLanguage(`EN`)
  }

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  }

  return (
    <Navbar color='white' light expand='md' className='fix-header ' >
      
      <div className='d-flex align-items-center'>
        <div className='d-lg-block d-none me-5 pe-3'>
        <Logo />
        </div>
        <NavbarBrand href='/'>
          <LogoWhite className='d-lg-none' />
        </NavbarBrand>
        <Button
          color='primary'
          className=' d-lg-none'
          onClick={() => showMobilemenu()}
        >
          <i className='bi bi-list'></i>
        </Button>
      </div>
      <div className='hstack gap-2'>
        <Button
          color='primary'
          size='sm'
          className='d-sm-block d-md-none'
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className='bi bi-x'></i>
          ) : (
            <i className='bi bi-three-dots-vertical'></i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen}>
        <Nav className='me-auto' navbar>

        </Nav>
        <UncontrolledDropdown inNavbar>
            <DropdownToggle caret nav>
            <img
              src={globeworldicon}
              alt='profile'
              className='rounded-circle'
              width='30'
            ></img>
            </DropdownToggle>
            <DropdownMenu end>
            <DropdownItem onClick={onChangeLangja}>日本語</DropdownItem>
            <DropdownItem onClick={onChangeLangen}>English</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          color='primary'
          style={{marginLeft: '2%', 'margin-right': '1%'}}
          onClick={() => handleLogin()}>
          {t('login')}
        </Button>
        {/* {count} */}
      </Collapse>

   </Navbar>
  );
};

export default LogoutHeader;
