import React from 'react';
import AppRouter from './routes/Router';

//global state 
// import { AuthContext } from './redux/Auth';

const App = () => {
  //global state 
  // const [userEmail,setUserEmail] = useState(null);
  // const [userName,setUserName] = useState(null);
  // const [checked,setChecked] = useState(false);
  // const [userEnterpriseID,setUserEnterpriseID] = useState(null);
  // const [useraccessToken, setUseraccessToken] = useState(null);
  // const [userAuthority, setUserAuthority] = useState(null);
  // const [selectEnID, setSelectEnID] = useState(null);
  // const [selectEnName, setSelectEnName] = useState(null);
  // const [step, setStep] = useState(null);
  // const [stepData, setStepData] = useState(null);
  // const [checkLangChang, setCheckLangChang] = useState(false);
  // const [errorChk, setErrorChk] = useState(null);

  return (
    <AppRouter />
  );
};

export default App;
