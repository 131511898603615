import React from 'react';
import { ReactComponent as LogoDark } from '../assets/images/logos/Logo2.svg';
// import { ReactComponent as LogoDark } from '../assets/images/logos/img_logo01.svg';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to='/home'>
      <LogoDark />
    </Link>
  );
};

export default Logo;
