export function isNull(val) {
    if(val == undefined || val == null || val == '' ) return true;
    else return false;
}
export function isValidity(val) {
    var reg = new RegExp(/^[ァ-ヶ０-９、・－＿／ー\u3000]*$/);
    if(reg.test(val)) return true;
    else return false;
  
}
export function checkGName(val) {
    var reg = new RegExp(/^[a-zA-Z0-9_+-あ-ん一-龠ァ-ヶ０-９、・－＿／ー\u3000]*$/);
    if(reg.test(val)) return true;
    else return false;
  
}

export function isZeroArray(arr) {
    if(arr == undefined || arr == null || arr.length < 1 || arr == '') return true;
    else return false;
}

export function checkLength(val, minLength, maxLength) {
    if (minLength > val || maxLength < val) return true;
    else return false;
}

export function checkEmail(val) {
    var reg = new RegExp(/^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,64}$/);
    if (reg.test(val)) return true;
    else return false;
}

export function checkPassword(val) {
    // var reg = new RegExp(/^(?=.*[A-Z])[a-zA-Z0-9.?/-]{8,64}$/);
    var reg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?#&%^*-_={}():;./])[A-Za-z\d$@$!%*?#&%^*-_={}():;./]{8,64}$/);
    if (reg.test(val)) return true;
    else return false;
}

export function checkEnterpriseID(val) {
    var reg = new RegExp(/^[a-zA-Z0-9]{9,9}$/); //20240716 英数字入力可対応
    if (reg.test(val)) return true;
    else return false;
}