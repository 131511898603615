import React, { useEffect } from 'react';
// when deploying use this
// import {useLocation } from 'react-router-dom';
import { lazy } from 'react';
//import { Navigate } from 'react-router-dom';
import { Routes, Route, Outlet } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import LogoutHeader from '../layouts/LogoutHeader';
import { StateContext } from '../context/StateContext';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies, protectedResources, loginRequest } from '../B2C';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

/****Layouts*****/
const FullLayout = lazy(() => import('../layouts/FullLayout.js'));

/***** Pages ****/

const Home = lazy(() => import('../views/Home.js'));
const LinearStepper = lazy(() => import('../views/LinearStepper.js'));
const InterviewListHome = lazy(() => import('../views/InterviewListHome.js'));
const InterviewStepper = lazy(() => import('../components/interview/InterviewStepper.js'));
// const Faq = lazy(() => import('../views/Faq.js'));
const AdminHome = lazy(() => import('../views/AdminHome.js'));
const ManageProjectGroup = lazy(() => import('../views/ManageProjectGroup.js'));
const CreateProjectGroup = lazy(() => import('../views/CreateProjectGroup.js'));
const ManageUser = lazy(() => import('../views/ManageUser.js'));
const CreateUser = lazy(() => import('../views/CreateUser.js'));
const ManageEnterprise = lazy(() => import('../views/ManageEnterprise.js'));
const CreateEnterprise = lazy(() => import('../views/CreateEnterprise.js'));
const PDFReader = lazy(() => import('../views/PDFReader.js'));
const Blank = lazy(() => import('../views/Blank.js'));
const ErrorPage = lazy(() => import('../views/ErrorPage.js'));
// when deploying use this
// const Maintenance = lazy(() => import('../views/Maintenance.js'));


/*****Routes******/

// const Router = createHashRouter([
//   // {
//   //   path: '/pdf', exact: true, element: <PDFReader />
//   // },
//   {
//     path: '/',
//     element: <p>as</p>
//     // element: <FullLayout />,
//     // children: [
//     //   { path: '/', exact: true, element: <Blank /> },
//     //   { path: 'home', exact: true, element: <Home /> },
//     //   // { path: 'FAQ', exact: true, element: <Faq /> },
//     //   { path: 'register', exact: true, element: <LinearStepper /> },
//     //   { path: 'modifyRegister/', exact: true, element: <LinearStepper /> },
//     //   { path: 'statas/', exact: true, element: <LinearStepper /> },
//     //   { path: 'copyRegister/', exact: true, element:<LinearStepper /> },

//     //   { path: 'AdminHome', exact: true, element: <AdminHome /> },
//     //   { path: 'ManageEnterprise', exact: true, element: <ManageEnterprise />},
//     //   { path: 'CreateEnterprise', exact: true, element: <CreateEnterprise /> },
//     //   { path: 'ManageUser', exact: true, element: <ManageUser />},
//     //   { path: 'CreateUser', exact: true, element: <CreateUser /> },
//     //   { path: 'ModifyUser', exact: true, element: <CreateUser /> },
//     //   { path: 'ModifyEnterprise', exact: true, element: <CreateEnterprise /> },
//     //   { path: 'ManageProjectGroup', exact: true, element: <ManageProjectGroup /> },
//     //   { path: 'CreateProjectGroup', exact: true, element: <CreateProjectGroup /> },
//     //   { path: 'ModifyProjectGroup', exact: true, element: <CreateProjectGroup /> },
//     //   // { path: 'pdf', exact: true, element: <PDFReader /> },
//     // ],
//   }
// ]);

const AuthenticateLayout = () => {
  const { instance } = useMsal();
  let account = instance.getAllAccounts();

  // when deploying use this
  // const location = useLocation();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
            const resetPasswordRequest = {
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: [...protectedResources.apiHello.scopes],
            };
            instance.loginRedirect(resetPasswordRequest);
        }
      } else if (event.eventType === EventType.HANDLE_REDIRECT_END && !account.length) {
        account = instance.getAllAccounts();
        // when not deploying use this
        if(!account.length) {
          instance.loginRedirect(loginRequest);
        }

        // when deploying use this
        // if(!account.length && location.pathname == '/adminlogin') {
        //   instance.loginRedirect(loginRequest);
        // }
      } else if (account && account[0] && account[0].idTokenClaims && !account[0].idTokenClaims.emails) {
        instance.loginRedirect(loginRequest);
      }

    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };

  }, [instance]);

  return (
    <>
      <AuthenticatedTemplate>
        <StateContext>
          <div className="dark">
            <FullLayout>
              <Outlet />
            </FullLayout>
          </div>
        </StateContext>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* when not deploying use this */}
        <LogoutHeader />
        {/* when deploying use this */}
        {/* <Maintenance/> */}
      </UnauthenticatedTemplate>
    </>
  )
}

const AppRouter = () => {

  return (
    <Routes>
      <Route path='/' element={<AuthenticateLayout />}>
        <Route path='/' exact element={<Blank />} />
        {/* when deploying use this */}
        {/* <Route path='/adminlogin' exact element={<Maintenance />} /> */}
        <Route path='/home' exact element={<Home />} />
        <Route path='/register' exact element={<LinearStepper /> } />
        <Route path='/modifyRegister/' element={<LinearStepper />} />
        <Route path='/statas/' element={<LinearStepper />} />
        <Route path='/copyRegister/' element={<LinearStepper />} />
        <Route path='/InterviewListHome' element={<InterviewListHome />} />
        <Route path='/cmi' element={<InterviewStepper />} />
        {/* <Route path='/CreateInterview' element={<InterviewStepper />} /> */}
        <Route path='/cmi' element={<InterviewStepper />} />
        {/* <Route path='/ModifyInterview' element={<InterviewStepper />} /> */}
      

        <Route path='/AdminHome' element={<AdminHome />} />
        <Route path='/ManageEnterprise' element={<ManageEnterprise />} />
        <Route path='/CreateEnterprise' element={<CreateEnterprise />} />
        <Route path='/ManageUser' element={<ManageUser />} />
        <Route path='/CreateUser' element={<CreateUser />} />
        <Route path='/ModifyUser' element={<CreateUser />} />
        <Route path='/ModifyEnterprise' element={<CreateEnterprise />} />
        <Route path='/ManageProjectGroup' element={<ManageProjectGroup />} />
        <Route path='/CreateProjectGroup' element={<CreateProjectGroup />} />
        <Route path='/ModifyProjectGroup' element={<CreateProjectGroup />} />
      </Route>
      
      <Route path='/pdf' exact element={<PDFReader />} />
      <Route path='/error' exact element={<ErrorPage />} />
    </Routes>
  )
}

export default AppRouter;
